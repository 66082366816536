@use "@ag-grid-community/styles" as ag;

// see https://ag-grid.com/javascript-data-grid/global-style-customisation-variables/#reference-variables
// It's best to set things here instead of in custom css rules below as much as possible
@include ag.grid-styles(
  (
    themes: (
      balham: (
        background-color: var(--hex-bkg-color-default),
        borders: false,
        borders-critical: true,
        border-radius: 0,
        borders-secondary: true,
        card-radius: var(--hex-border-radius),
        cell-horizontal-padding: 6px,
        chip-background-color: null,
        disabled-foreground-color: var(--hex-font-color-muted),
        font-family: var(--hex-font-family-default),
        foreground-color: var(--hex-font-color-default),
        secondary-foreground-color: var(--hex-font-color-muted),
        grid-size: 4px,
        header-background-color: var(--hex-bkg-color-muted),
        header-column-separator-color: var(--hex-border-color-muted),
        header-column-separator-height: 100%,
        header-height: 28px,
        input-focus-box-shadow: "none",
        modal-overlay-background-color: var(--hex-bkg-color-muted),
        odd-row-background-color: null,
        popup-shadow: var(--hex-box-shadow-popover),
        row-border-color: var(--hex-border-color-muted),
        row-hover-color: var(--hex-hover-color),
        column-hover-color: var(--table-active-calc-background-color),
      ),
      quartz: (
        background-color: var(--hex-bkg-color-default),
        borders: false,
        borders-critical: true,
        border-color: var(--hex-border-color-muted),
        border-radius: 0,
        card-radius: var(--hex-border-radius),
        font-size: var(--hex-font-size-small),
        font-family: var(--hex-font-family-default),
        header-foreground-color: var(--hex-font-color-muted),
        header-background-color: var(--hex-bkg-color-default),
        header-column-separator-color: var(--ag-border-color),
        header-column-resize-handle-display: block,
        header-column-resize-handle-width: 1px,
        header-height: 36px,
        input-focus-box-shadow: "none",
        modal-overlay-background-color: var(--hex-bkg-color-muted),
        odd-row-background-color: null,
        popup-shadow: var(--hex-box-shadow-popover),
        row-border-color: var(--ag-border-color),
        row-border-style: none,
        row-hover-color: var(--hex-hover-color),
        selected-row-background-color: var(--hex-bkg-color-selected),
        foreground-color: var(--hex-font-color-default),
        disabled-foreground-color: var(--hex-font-color-muted),
        secondary-foreground-color: var(--hex-font-color-muted),
        grid-size: 9px,
        cell-horizontal-padding: 8px,
        chip-background-color: null,
      ),
    ),
  )
);

.ag-theme-quartz {
  .ag-icon {
    color: var(--hex-icon-color);
  }

  .ag-root-wrapper {
    border-radius: 0;
    border-top: var(--ag-borders-critical) var(--ag-border-color);
  }

  .ag-row-last:not(.ag-row-loading) {
    border-bottom: var(--ag-borders-critical) var(--ag-border-color);
    border-radius: 0;
  }

  .ag-row-loading:not(:only-of-type) {
    display: none !important;
  }

  .ag-root:has(> .ag-body ~ .ag-overlay:not(.ag-hidden)) {
    .ag-row-loading:not(:last-of-type) {
      display: none !important;
    }
  }

  /* Note: the style rules in this block are a copy of the [ag-grid hover styles](https://github.com/ag-grid/ag-grid/blob/latest/community-modules/theming/src/parts/core/css/grid-layout.css#L170) */
  /* We have to define styles for the `::before` content here so we can add an animated transition for the row hover background color */
  .ag-row:not(.ag-row-hover)::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
  }

  .ag-row.ag-row-hover:not(.ag-full-width-row)::before {
    transition: background-color var(--hex-animation-duration)
      var(--hex-animation-easing);
  }

  .ag-overlay-wrapper,
  .ag-overlay-loading-wrapper,
  .ag-overlay-no-rows-wrapper {
    background-color: transparent;
    box-shadow: none;
    pointer-events: auto;
  }

  .interactive-row:not(.ag-row-loading) {
    cursor: pointer;
  }

  .ag-center-cols-container {
    /* Note: `!important` needed here to override dynamic inline styles set by ag grid */
    /* this is needed to ensure the last row border styles extend the full width of the container, even when columns are hidden  */
    min-width: 100% !important;
  }

  .ag-center-cols-container .ag-row .ag-cell:first-of-type,
  .ag-header-viewport
    .ag-header-container
    .ag-header-row
    .ag-header-cell:first-of-type {
    padding-left: 10px;
  }

  .ag-header {
    border-radius: 0;
    font-size: var(--hex-font-size-small);
    font-weight: var(--hex-font-weight-regular);
    line-height: 20px;

    .sr-only {
      // Used for columns with no visual header
      clip: rect(0 0 0 0);
      clip-path: inset(50%);
      height: 1px;
      overflow: hidden;
      position: absolute;
      white-space: nowrap;
      width: 1px;
    }
  }

  .ag-body-vertical-scroll {
    // Pretty hacky here, these are the styles applied by `.ag-scrollbar-invisible`; Would rather
    // just apply the CSS class to the "fake" scroll container, but I don't see an api for that.
    right: 0;
    top: 0;
    z-index: 10;
    position: absolute;
    bottom: 0;
  }
  .ag-body-horizontal-scroll {
    // Pretty hacky here, these are the styles applied by `.ag-scrollbar-invisible`; Would rather
    // just apply the CSS class to the "fake" scroll container, but I don't see an api for that.
    left: 0;
    right: 0;
    position: absolute;
    bottom: 0;
    background: var(--hex-bkg-color-default);
  }

  .ag-header-cell.push-right {
    // Note: `.push-right` is a custom class added by us (e.g. not a class defined by AG Grid) to force alignment
    // of our left-most column of cells. This is necessary after we forced the table to take full width of the page,
    // using negative margins, to properly align the first cell's column to the page header content.
    padding-left: 32px;
  }

  .ag-cell.push-right {
    // Note: `.push-right`is a custom class added by us (e.g. not a class defined by AG Grid) to force alignment
    // of our left-most column of cells. This is necessary after we forced the table to take full width of the page,
    // using negative margins, to properly align the first cell's column to the page header content.
    padding-left: 29px;
  }
}

/* Hacky overrides for AG Grid styles. When possible, please set things above instead of as a style here. */
.ag-theme-balham.ag-theme-balham {
  --ag-range-selection-background-color: var(
    --hex-table-range-selection-background-color
  );
  --ag-range-selection-border-color: var(
    --hex-table-range-selection-border-color
  );

  .ag-header {
    border-color: var(--hex-border-color-muted);
  }

  // Border for pinned columns
  .ag-pinned-left-header,
  .ag-pinned-left-floating-bottom,
  .ag-pinned-left-cols-container
    .ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(
      .ag-cell-range-single-cell:not(.no-horizontal-padding)
    ) {
    border-right: 2px solid var(--hex-border-color-muted);
  }

  // Scroll container border for pinned columns
  .ag-body-horizontal-scroll:not(.ag-scrollbar-invisible)
    .ag-horizontal-left-spacer:not(.ag-scroller-corner) {
    border-right: 2px solid var(--hex-border-color-muted);
  }

  .ag-header-group-cell,
  .ag-header-cell {
    padding-right: 0px;
    padding-left: 0px;

    border-top-color: var(--hex-border-color-default) !important;

    // Allow the drag handle to be grabbed from either side
    overflow: visible;

    // Create new stacking context so ::after element below as well as other children aren't in the global context.
    isolation: isolate;

    // hide the drag handle that by default would appear to the right of our 'spacer' column
    &.header-spacer::after {
      visibility: hidden;
    }

    &.active-calc,
    &.referenced-calc,
    &.highlighted-column {
      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: -1px;
        left: 0;
        z-index: 2;
        pointer-events: none;
      }
    }

    &.referenced-calc::after,
    &.highlighted-column::after {
      border-left: 1px dashed var(--table-active-calc-border-color);
      border-right: 1px dashed var(--table-active-calc-border-color);
      border-top: 1px dashed var(--table-active-calc-border-color);
    }

    &.ag-column-hover.ignore-column-hover {
      background-color: var(--hex-bkg-color-muted);
    }
  }

  .ag-center-cols-container .ag-row-last {
    outline: 1px solid var(--hex-border-color-muted);
  }

  // Override minimum height in existing CSS rules so that table doesn't have empty space with only one row.
  // See https://github.com/ag-grid/ag-grid/issues/4095 for a similar issue
  .ag-layout-auto-height .ag-center-cols-clipper,
  .ag-layout-auto-height .ag-center-cols-container,
  .ag-layout-auto-height .ag-center-cols-viewport,
  .ag-layout-print .ag-center-cols-clipper,
  .ag-layout-print .ag-center-cols-container,
  .ag-layout-print .ag-center-cols-viewport {
    min-height: 26px;
  }

  .ag-ltr .ag-cell {
    // adds a vertical border between cells when they don't need to show a selection outline
    &:not(.ag-cell-range-right):not(.ag-cell-range-single-cell):not(
        .no-horizontal-padding
      ) {
      border-right: 1px solid var(--hex-border-color-muted);
    }

    &.ag-cell-wrap-text {
      word-break: break-word;
    }

    // rules for custom classes we apply to cells
    &.cell-no-select {
      &:focus,
      &.ag-cell-focus {
        // prevent cell from getting a blue border when clicked
        // see https://github.com/ag-grid/ag-grid/issues/1918
        border-color: transparent var(--hex-border-color-muted) transparent
          transparent;
        border-width: 1px;
        outline: none;
      }
    }

    &.cell-index-column {
      color: var(--hex-font-color-muted);
      text-align: center;
      &.cell-named-index-column {
        text-align: unset;
      }

      background: var(--hex-bkg-color-muted);
    }

    &.cell-index-column-clickable {
      &:hover {
        background: var(--hex-hover-color);
      }

      cursor: pointer;
    }

    &.cell-index-column-add-row-btn {
      display: flex;
      align-items: center;

      color: var(--hex-font-color-muted);
    }

    // cells in our flexible "spacer" column never need padding or border since
    // they're always empty, unselectable, and on the right.
    &.cell-spacer {
      padding: 0;
    }

    &.cell-error,
    &.cell-error .ag-input-field-input {
      background-color: rgba(var(--hex-intent-danger), 0.1);
    }

    &.cell-index-bottom-border {
      border-bottom-color: var(--hex-border-color-muted);
    }

    &.active-calc.active-calc.active-calc,
    &.highlighted-column.highlighted-column.highlighted-column {
      background-color: var(--table-active-calc-background-color);
    }

    &.referenced-calc.referenced-calc.referenced-calc,
    &.highlighted-column.highlighted-column.highlighted-column {
      border-left: 1px dashed var(--table-active-calc-border-color);
      border-right: 1px dashed var(--table-active-calc-border-color);
    }

    &.calc-preview-data {
      color: var(--hex-font-color-muted);
      font-style: italic;
    }

    &.placeholder-cell {
      background-color: var(--table-placeholder-cell-background-color);
      border-bottom: 0;
      border-top: 0;
    }

    &.click-to-add-to-formula {
      cursor: pointer;
    }

    &.null-value {
      color: var(--hex-font-color-placeholder);
    }

    &.multi-row-span {
      border-bottom: 1px solid var(--hex-border-color-muted);
      background-color: var(--hex-bkg-color-default);

      &.ag-cell-focus,
      &.ag-cell-range-selected,
      &.ag-cell-range-selected-1,
      &.ag-cell-range-single-cell {
        background-color: var(--hex-bkg-color-default);
      }

      &.ag-cell-range-selected:not(.ag-cell-range-single-cell) {
        background-color: var(
          --hex-table-range-selection-background-color-opaque
        );
      }
    }
  }

  .ag-ltr .ag-body-viewport:not(.ag-has-focus) .ag-cell {
    &.multi-row-span {
      &.ag-cell-focus,
      &.ag-cell-range-selected,
      &.ag-cell-range-selected-1,
      &.ag-cell-range-single-cell {
        background-color: var(
          --hex-table-range-selection-background-color-opaque
        );
      }
    }
  }

  .no-horizontal-padding {
    padding-left: 0;
    padding-right: 0;
    background-color: var(--hex-bkg-color-muted);
    border: none;
  }

  .ag-row-last {
    border-bottom: none;

    .ag-cell.referenced-calc,
    .ag-cell.highlighted-column {
      border-bottom: 1px dashed var(--table-active-calc-border-color);
    }
  }

  .context-menu-danger {
    color: var(--hex-intent-danger);
  }

  .hidden-columns-left {
    border-left: 2px solid var(--hex-border-color-muted);
  }

  /* #region override right-pinned column border and replace with our box shadow */
  .ag-cell.ag-cell-first-right-pinned:not(.ag-cell-range-left):not(
      .ag-cell-range-single-cell
    ) {
    border-left: none;
  }

  .ag-pinned-right-cols-container {
    border-left: 1px solid var(--hex-border-color-muted);
    background-color: var(--hex-bkg-color-default);
  }

  .ag-pinned-right-header {
    border-left: 1px solid var(--hex-border-color-muted);
  }

  .ag-body-horizontal-scroll:not(.ag-scrollbar-invisible)
    .ag-horizontal-right-spacer:not(.ag-scroller-corner) {
    border-left: 1px solid var(--hex-border-color-muted);
  }

  /* column drag and drop styles */
  &.ag-dnd-ghost {
    color: var(--hex-font-color-muted);
    background-color: var(--hex-bkg-color-muted);
    font-weight: var(--hex-font-weight-normal);
    font-size: var(--hex-font-size-small);
    border: 0;
    box-shadow: var(--hex-box-shadow-popover);
    border-radius: 2px;

    /* We don't render any icons with this animation, but having the animation
    on an empty element causes a spacing issue so we disable it */
    .ag-shake-left-to-right {
      animation: none;
    }
  }

  /* Totals bar footer styling */
  .ag-floating-bottom {
    border-top-color: var(--hex-border-color-muted);
    background-color: var(--hex-bkg-color-muted);
  }

  .ag-pinned-right-floating-bottom {
    border-left: 1px solid var(--hex-border-color-muted);
  }

  .ag-menu {
    .ag-menu-list {
      padding: 5px;
    }

    .ag-menu-option {
      border-radius: var(--hex-border-radius);

      .ag-icon {
        color: var(--hex-icon-color);
      }

      .ag-menu-option-text {
        font-size: var(--hex-font-size-default);
      }

      .ag-menu-option-shortcut {
        color: var(--hex-font-color-muted);
      }
    }

    .ag-menu-separator .ag-menu-separator-part::after {
      border-color: var(--hex-border-color-muted);
    }
  }

  .cell-spacer.ag-cell,
  .header-spacer.ag-header-cell {
    border-width: 0 !important;
  }

  /* #endregion */
}

/*
  Generally all styles should be applied in the specific theme regions above.
  However, we need this rule to be very un-specific so it doesn't override other built-in
  AG Grid CSS rules, so we have to declare it here.
*/
.ignore-column-hover {
  background-color: transparent;
}

.detail-columns-border {
  border-left: 2px solid var(--hex-border-color-muted);
}
